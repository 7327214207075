// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`after changing value calls onChange 1`] = `
[MockFunction] {
  "calls": Array [
    Array [
      "item1",
    ],
  ],
  "results": Array [
    Object {
      "type": "return",
      "value": undefined,
    },
  ],
}
`;

exports[`after changing value renders as expected 1`] = `
<select
  className="form-control"
  id="root"
  key="root"
  onChange={[Function]}
  value=""
>
  <option
    key="blank"
    value=""
  />
  <option
    key="item1"
    value="item1"
  >
    First
  </option>
  <option
    key="item2"
    value="item2"
  >
    Second
  </option>
</select>
`;

exports[`renders as expected 1`] = `
<select
  className="form-control"
  id="root"
  key="root"
  onChange={[Function]}
  value=""
>
  <option
    key="blank"
    value=""
  />
  <option
    key="item1"
    value="item1"
  >
    First
  </option>
  <option
    key="item2"
    value="item2"
  >
    Second
  </option>
</select>
`;
