// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`currTextValue computes currTextValue (basic value) 1`] = `"foo"`;

exports[`currTextValue computes currTextValue (cascading select with nothing selected) 1`] = `null`;

exports[`currTextValue computes currTextValue (cascading select with something selected) 1`] = `"One"`;

exports[`rightQingOptions reaction filters out all qings when leftQing is select_multiple 1`] = `Array []`;

exports[`rightQingOptions reaction filters out the correct qings when leftQing is datetime 1`] = `
Array [
  Object {
    "id": "2",
    "numeric": false,
    "qtypeName": "datetime",
    "textual": false,
  },
  Object {
    "id": "4",
    "numeric": false,
    "qtypeName": "datetime",
    "textual": false,
  },
]
`;

exports[`rightQingOptions reaction filters out the correct qings when leftQing is numeric 1`] = `
Array [
  Object {
    "id": "3",
    "numeric": true,
    "qtypeName": "integer",
    "textual": false,
  },
  Object {
    "id": "4",
    "numeric": true,
    "qtypeName": "decimal",
    "textual": false,
  },
]
`;

exports[`rightQingOptions reaction filters out the correct qings when leftQing is select_one 1`] = `
Array [
  Object {
    "id": "2",
    "numeric": false,
    "optionSetId": "1",
    "qtypeName": "select_one",
    "textual": false,
  },
  Object {
    "id": "4",
    "numeric": false,
    "optionSetId": "1",
    "qtypeName": "select_one",
    "textual": false,
  },
]
`;

exports[`rightQingOptions reaction filters out the correct qings when leftQing is text 1`] = `
Array [
  Object {
    "id": "3",
    "numeric": false,
    "qtypeName": "text",
    "textual": true,
  },
  Object {
    "id": "4",
    "numeric": false,
    "qtypeName": "longtext",
    "textual": true,
  },
]
`;

exports[`rightQingOptions reaction is empty with no leftQingId 1`] = `Array []`;
