// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`popover handles callbacks 1`] = `1`;

exports[`popover handles callbacks 2`] = `
Array [
  Object {
    "type": "return",
    "value": undefined,
  },
]
`;

exports[`renders as expected 1`] = `
<FilterOverlayTrigger
  hints={
    Array [
      "One",
    ]
  }
  id="form-filter"
  onSubmit={[MockFunction]}
  popoverContent={
    <Select2
      data={
        Array [
          Object {
            "id": "1",
            "text": "One",
          },
          Object {
            "id": "2",
            "text": "Two",
          },
          Object {
            "id": "3",
            "text": "Q\\"uo'te\`s",
          },
        ]
      }
      onSelect={[Function]}
      onUnselect={[Function]}
      options={
        Object {
          "allowClear": true,
          "dropdownCssClass": "filters-select2-dropdown",
          "placeholder": "Choose a form",
          "width": "100%",
        }
      }
      value="2"
    />
  }
  title="Form"
/>
`;
