// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`callbacks submits 1`] = `
[MockFunction] {
  "calls": Array [
    Array [],
  ],
  "results": Array [
    Object {
      "type": "return",
      "value": undefined,
    },
  ],
}
`;

exports[`renders as expected (with info button) 1`] = `
<div
  className="d-flex"
>
  <i
    className="fa fa-search"
  />
  <input
    autoComplete="off"
    className="form-control search-str"
    name="search"
    onChange={[Function]}
    onKeyDown={[Function]}
    placeholder="Search"
    type="text"
    value="query"
  />
  <Button
    className="btn-apply btn-advanced-search"
    onClick={[MockFunction]}
    variant="secondary"
  >
    Search
  </Button>
  <i
    aria-label="[missing \\"en.search.help_title\\" translation]"
    className="fa fa-info-circle hint"
    onClick={[Function]}
    onKeyDown={[Function]}
    role="button"
    tabIndex={0}
  />
</div>
`;

exports[`renders as expected (with query param) 1`] = `
<div
  className="d-flex"
>
  <i
    className="fa fa-search"
  />
  <input
    autoComplete="off"
    className="form-control search-str"
    name="search"
    onChange={[Function]}
    onKeyDown={[Function]}
    placeholder="Search"
    type="text"
    value="query"
  />
  <Button
    className="btn-apply btn-advanced-search"
    onClick={[MockFunction]}
    variant="secondary"
  >
    Search
  </Button>
</div>
`;

exports[`renders as expected (without query param) 1`] = `
<div
  className="d-flex"
>
  <i
    className="fa fa-search"
  />
  <input
    autoComplete="off"
    className="form-control search-str"
    name="search"
    onChange={[Function]}
    onKeyDown={[Function]}
    placeholder="Search"
    type="text"
    value="query"
  />
  <Button
    className="btn-apply btn-advanced-search"
    onClick={[MockFunction]}
    variant="secondary"
  >
    Search
  </Button>
</div>
`;
